@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300&display=swap");

/* Global Styles */
:root {
  --primary-color: black;
  --accent-color: #bc2929;
  --dark-grey: #767676;
  --light-grey: #c4c4c4;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-weight: 400;
  height: 100%;
  font-size: 1rem;
  line-height: 1.6;
  background-color: #fff;
  color: #333;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

ul {
  list-style: none;
}

/* Utilities */
.container {
  max-width: 900px;
  margin: auto;
  overflow: show;
  padding: 0 2rem;
  margin-top: 12rem;
  margin-bottom: 3rem;
  position: relative;
}

.custom-container-1 {
  overflow: show;
  margin-bottom: 0;
  padding-bottom: 0;
  margin: 0rem auto;
  margin-left: 1rem;
  margin-right: auto;
  margin-top: 12rem;

  width: 86%;
}
.custom-container-2 {
  overflow: show;
  margin-bottom: 0;
  padding-bottom: 0;
  margin: 0rem auto;
  margin-top: 12rem;

  width: 80%;
}

.img-center {
  display: block;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.light-grey {
  background: var(--light-grey);
}

.dark-grey {
  background: var(--dark-grey);
}

.inline {
  display: inline-block;
}

.flex {
  display: flex;
  justify-content: space-evenly;
}

.flex-table {
  display: flex;
  flex-direction: column;
}

.block {
  display: block;
}

.full-width {
  border: 1px solid red;
  width: 100%;
}

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.large0 {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 0;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.center {
  align-content: center;
}

.vertical-center {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 3rem;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-b800 {
  font-weight: 800;
}

.text-bold {
  font-size: 1.2rem;
  font-weight: bolder;
}

.w80 {
  margin-left: 10%;
  width: 80%;
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}

.pt-1 {
  padding-top: 1rem;
}

.pb {
  padding-bottom: 0.5rem;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.my-4 {
  margin: 4rem 0;
}
.my-5 {
  margin: 4rem 0;
}

.mb- {
  margin-bottom: 0;
}

.mb {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 4rem;
}

.mt-4 {
  margin-top: 4rem;
}
.mt-12 {
  margin-top: 12rem;
}

.mr {
  margin-right: 0.5rem;
}
.mr-4 {
  margin-right: 4rem;
}

.ml {
  margin-left: 0.5rem;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  align-items: center;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  border-bottom: solid 4px var(--accent-color);
  /* opacity: 0.9; */
}

.navbar ul {
  display: flex;
}

#navbar-login {
  /* margin-top: 7rem; */
  /* text-align: left; */
  bottom: 0;
  font-size: 2rem;
  font-weight: bolder;
  margin-right: 30rem;
}

.nav-active {
  border-bottom: 3px solid var(--accent-color);
}

.img-navbar {
  height: 7rem;
}

/* .navbar #login {
  color: #000;
  padding: 0.45rem;
  margin: 0 0.25rem;
} */

.navbar a {
  /* color: #fff; */
  font-size: 1.2rem;
  padding: 0.45rem 0.4rem;
  margin: 0 1rem;
}

.navbar a:hover {
  color: var(--primary-color);
}

.navbar .welcome span {
  margin-right: 0.6rem;
}

/* Forms */
.form .form-group {
  margin-top: 0.15rem;
  margin-bottom: 1.2rem;
  margin-right: 0;
  margin-left: 0;
}
.form .form-group-mb0 {
  margin-top: 0.15rem;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form input[type="number"],
.form select,
.form textarea {
  /* display: block; */
  width: 100%;
  padding: 0.9rem 0.9rem;
  font-size: 1.2rem;
  border: 1px solid grey;
}

/* Input with icon */
/* Style the input container */
.icon-input-container {
  display: flex;
  width: 100%;
  /* margin-bottom: 15px; */
}

/* Style the form icons */
.input-icon {
  position: absolute;
  height: 0.5rem;
  margin: 1.1rem;
  font-size: 1rem;
  height: 1rem;
  float: right;
  right: 4rem;
}
.input-icon:hover {
  cursor: pointer;
}

/* Style the input fields */
.icon-input-field {
  /* position: absolute; */
  width: 100%;
  /* padding: 0.9rem 0.9rem; */
  font-size: 1.2rem;
  border: 1px solid #ccc;
  outline: none;
  /* text-align: left; */
}
/* /Input with icon */
.form input[type="submit"],
button {
  width: 100%;
  padding: 0.8rem;
  font: inherit;
}

/* Buttons */
.button-light {
  color: white;
  font: inherit;
  border: solid 1px #9b9b9b;
}

.button-dark {
  color: white;
  font: inherit;
  border: 0;
  box-shadow: 0;
}

.button-danger {
  background: var(--danger-color);
  color: white;
  border: 0px;
}

.button-log-out {
  text-align: center;
  font-size: 1.2rem;
  padding: 1rem 3rem 1rem 3rem;
}

.link-as-button {
  display: inline-block;
  width: 100%;
  padding: 0.8rem;
  font: inherit;
}
/* .button-light {
  display: inline-block;
  background: var(--light-grey);
  color: #333;
  padding: 0.4rem 1.3rem;
  font-size: 1rem;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: opacity 0.2s ease-in;
  outline: none;
} */

/* Landing */
.landing {
  position: relative;
  background-attachment: fixed;
  height: 100vh;
}

.landing-inner {
  color: #fff;
  height: 100%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.form input[type="submit"].right50 {
  float: right;
  width: 50%;
}

button.right50 {
  float: right;
  width: 50%;
}

.auto-flex {
  flex: 1 1 auto;
}

.hack {
  border: 1px solid white;
}

.custom-file-input::-webkit-file-upload-button {
  width: 0.1px;
  height: 0.1px;
  position: absolute;
  visibility: hidden;
  /* border: 1px solid red; */
}
.custom-file-input::before {
  content: "Datei auswählen";
  width: 100%;
  border: 1px solid blue;
  /* display: inline-block;
  width: 100%;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt; */
}
.custom-file-input:hover::before {
  border-color: black;
}
/* .custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
} */

/* Alerts */

.alert {
  align-self: flex-end;
  padding: 0.8rem;
  margin: 0.5rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.alert-danger {
  background: var(--danger-color);
}
.alert-success {
  background: var(--success-color);
}

/* Tables */
table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 80%;
  /* padding: 0; */
  margin: 1rem auto;
}

table td {
  text-align: center;
  padding: 8px;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  border: 1px solid #ddd;
  padding: 7px;
  text-align: start;
  flex: 1 1 auto;
  padding-bottom: 0.5rem;
  vertical-align: text-top;
  text-align: center;
  white-space: nowrap;
  background-color: #c4c4c4;
  color: black;
  width: 4rem;
  height: 3rem;
  position: relative;
}

table th input {
  position: absolute;
  /* display: block; */
  flex: 1 1 auto;
  border: 1px solid grey;
  bottom: 0;
  left: 0;
  margin-top: 0.5rem;
  height: 1rem;
  width: 100%;
  height: 2rem;
  /* border: none; */
  padding: 10px;
  box-sizing: border-box;
}

/* Making links actually look like links */

a.link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

a.link:visited {
  color: blueviolet;
  text-decoration: underline;
}

a.link:hover {
  text-decoration: none;
}

a.link-black {
  color: black;
}
.link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

.link:visited {
  color: blueviolet;
  text-decoration: underline;
}

.link:hover {
  text-decoration: none;
}

.link-black {
  color: black;
}

.dot {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
}

.dot.green {
  background-color: rgb(50, 200, 50);
}
.dot.red {
  background-color: rgb(200, 50, 50);
}
.dot.table {
  align-self: center;
  margin: auto;

  display: inline-block;
}

.loader {
  border: 0.3rem solid var(--primary-color); /* Blue */
  border-bottom: 0.3rem solid #f3f3f3; /* Light grey */

  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  animation: spin 1s linear infinite;
}

.loader-large {
  border: 0.4rem solid var(--primary-color); /* Blue */
  border-bottom: 0.4rem solid #f3f3f3; /* Light grey */

  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: spin 1s linear infinite;
}

.test {
  margin: 0;
  border: 1px solid red;
}

.download_icon {
  width: 1.5rem;
  height: 1.5rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }

  /* Navbar */
  .navbar {
    box-sizing: border-box;
    display: block;
    text-align: start;
  }

  .navbar ul {
    box-sizing: border-box;
    text-align: center;
    justify-content: center;
  }
  .navbar li {
    padding: 0;
    box-sizing: border-box;
  }

  .navbar span {
    font-size: 0.7rem;
    margin-top: 0;
  }

  .navbar a,
  .sm-test {
    font-size: 0.8rem;
  }

  .navbar .welcome {
    display: none;
  }

  .button-log-out {
    text-align: center;
    font-size: 0.7rem;
    padding: 0.5rem 1.5rem;
  }
}

.foobar {
  float: right;
  display: inline;
  width: 49%;
}
